import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'
import Post from '../components/post'

const BlogPost = ( {data, pageContext } ) => {
  const {
    frontmatter: { title, date, path, blurb, tags },
    id,
    body,
  } = data.mdx
  const { next, previous } = pageContext

  return (
    <Layout>
      <SEO title={title} description={blurb} />
      <Post
        key={id}
        title={title}
        date={date}
        path={path}
        body={body}
        tags={tags}
        previousPost={previous}
        nextPost={next}
      />
    </Layout>
  )
}

export const query = graphql`
query($path: String) {
  mdx(frontmatter: { path: { eq: $path } }) {
    frontmatter {
      title
      date
      path
      blurb
      tags
      coverImage {
        childImageSharp {
          gatsbyImageData(
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            transformOptions: { fit: INSIDE }
          )
        }
      }
    }
    id
    body
  }
}`

BlogPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        blurb: PropTypes.string.isRequired,
        tags: PropTypes.array.isRequired,
      }).isRequired,
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    next: PropTypes.object,
    previous: PropTypes.object,
  }),
}

export default BlogPost
